import styled from "styled-components";

interface TagProps {
  currWidth: number;
}

export const Tag = styled.div<TagProps>`
  font-size: ${({ currWidth }) => (31 / 1500) * currWidth}px;
  border-radius: ${({ currWidth }) => (9 / 1500) * currWidth}px;
  padding: ${({ currWidth }) => (8 / 1500) * currWidth}px;
  padding-left: ${({ currWidth }) => (16 / 1500) * currWidth}px;
  padding-right: ${({ currWidth }) => (12 / 1500) * currWidth}px;
  background-color: black;
  background-color: #2a95a0;
  font-weight: 600;
  color: white;
`;

export const Arrow = styled.div<TagProps>`
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: ${({currWidth}) => `${(30 /1500) * currWidth}px solid #2a95a0` } ;
  border-bottom: ${({currWidth}) => `${(30 /1500) * currWidth}px solid transparent` };
  left:  ${({currWidth}) => `${(10 /1500) * currWidth}px` };
  z-index: -1;
`;
