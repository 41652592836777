import React, { useEffect, useMemo, useRef, useState } from "react";
import imageEx from "./../../images/ex.jpg";

import "./App.css";
import Highlighter from "react-highlight-words";
import domtoimage from "dom-to-image";
import { saveAs } from "file-saver";
import Select from "react-select";
import {
  MainContainer,
  ImageDiv,
  ImageDivContent,
  Image,
  TemplateText,
  SourceText,
  Logo,
} from "./styles";
import Tag from "../../components/tag";
import GradientComponent from "../../components/gradient";

function App() {
  const imageDiv = useRef(null);
  const [currWidth, setCurrWidth] = useState(0);
  const [select, setSelect] = useState([]);
  const [fontScale, setScale] = useState(72 / 1500);
  const [sourceBottomScale, setSBC] = useState(568 / 1500);
  const [sourceText, setSourceText] = useState("");
  const [text, setText] = useState("");
  const [highlight, setHighlight] = useState("");
  const [paddingScale] = useState(77 / 1500);
  const [topScale] = useState(1046 / 1500);
  const [file, setFile] = useState([]);
  const [bottomAddition, setBottomAddition] = useState(0);
  const sourceTextRef = useRef(null);

  const options = [
    { value: "#BREAKINGNEWS", label: "BREAKINGNEWS" },
    { value: "#CIMAHI", label: "CIMAHI" },
    { value: "#INFOCOVID-19", label: "INFOCOVID" },
    { value: "#TEKNOLOGI", label: "TEKNOLOGI" },
    { value: "#INFOKESEHATAN", label: "INFOKESEHATAN" },
    { value: "#MUSIK", label: "MUSIK" },
    { value: "#INFOGRAFIS", label: "INFOGRAFIS" },
    { value: "#CIMAHIYUMMY", label: "CIMAHIYUMMY" },
    { value: "#OTOMOTIF", label: "OTOMOTIF" },
    { value: "#FOTOGRAFI", label: "FOTOGRAFI" },
  ];

  useEffect(() => {
    if (imageDiv) setCurrWidth(imageDiv.current.clientWidth);
  }, []);

  useEffect(() => {
    if (sourceTextRef) setBottomAddition(sourceTextRef.current.clientWidth);
  }, [sourceText]);

  const onTextChange = (e) => {
    setText(e.target.value);
  };

  const onHighlightChange = (e) => {
    setHighlight(e.target.value);
  };

  const onSourceChange = (e) => {
    setSourceText(e.target.value);
  };
  const onChangeSelect = (value) => {
    setSelect(value);
  };
  const save = () => {
    const scale = 3;
    domtoimage
      .toPng(imageDiv.current, {
        height: imageDiv.current.offsetHeight * scale,
        width: imageDiv.current.offsetWidth * scale,
        style: {
          transform: "scale(" + scale + ")",
          transformOrigin: "top left",
          width: imageDiv.current.offsetHeight + "px",
          height: imageDiv.current.offsetWidth + "px",
        },
      })
      .then(function (blob) {
        // var img = new Image();
        // img.src = dataUrl;
        // document.body.appendChild(img);

        saveAs(blob, `infocimahi-${highlight}`);
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
      });
  };

  const onInputFile = (e) =>
    setFile({
      ...e.target.files[0],
      imagePath: URL.createObjectURL(e.target.files[0]),
    });

  return (
    <div className="App">
      <MainContainer>
        <ImageDiv ref={imageDiv}>
          <ImageDivContent>
            <Image
              src={file.imagePath || imageEx}
              defaultValue={imageEx}
              alt="imageEx"
              style={{ objectFit: "cover" }}
            />

            {useMemo(
              () => (
                <GradientComponent currWidth={currWidth} />
              ),
              [currWidth]
            )}

            <SourceText
              zIndex={2}
              sourceBottomScale={sourceBottomScale}
              currWidth={currWidth}
              topSize={bottomAddition}
              ref={sourceTextRef}
            >
              sumber : {sourceText}
            </SourceText>
            <Logo src="logo-main.png" currWidth={currWidth}></Logo>

            <TemplateText
              topScale={topScale}
              fontScale={fontScale}
              paddingScale={paddingScale}
              currWidth={currWidth}
            >
              <Highlighter
                // highlightClassName="highlight-text"
                searchWords={[highlight]}
                // autoEscape={true}
                highlightStyle={{
                  display: "inline-block",
                  padding: `${(10 / 1500) * currWidth}px ${
                    (17 / 1500) * currWidth
                  }px`,
                  backgroundColor: "#ffde17",
                  color: "#231f20",
                  borderRadius: (32 / 1500) * currWidth,
                  marginBlockStart: 0,
                }}
                textToHighlight={text}
              />
              {/* historich kembali berjaya, setelah 600 tahun */}
            </TemplateText>
            <div
              style={{
                position: "absolute",
                zIndex: 20,
                right: (33 / 1500) * currWidth,
                top: (33 / 1500) * currWidth,
                width: "100%",
                display: "flex",
                flexDirection: "row-reverse",
              }}
            >
              {select.map((item) => (
                <Tag text={item.value} currWidth={currWidth} />
              ))}
            </div>
          </ImageDivContent>
        </ImageDiv>
        <div style={{ paddingTop: 8, width: "100%", maxWidth: 512 }}>
          <div style={{ paddingBottom: 8 }}>
            <input type="file" text="Image" onChange={onInputFile} />
          </div>
          <div style={{ paddingBottom: 8 }}>
            <textarea onChange={onTextChange} placeholder="Text" type="text" />
          </div>
          <div style={{ paddingBottom: 8 }}>
            <input
              onChange={onHighlightChange}
              placeholder="Highlight"
              type="text"
            />
          </div>
          <div style={{ paddingBottom: 8 }}>
            <input onChange={onSourceChange} placeholder="Sumber" type="text" />
          </div>
          <div style={{ paddingBottom: 8, paddingTop: 4 }}>
            <Select options={options} onChange={onChangeSelect} isMulti />
          </div>

          <button
            onClick={save}
            style={{
              marginTop: 8,
              marginBottom: 16,
              padding: "8px 16px",
              width: "100%",
              backgroundColor: "#2a95a0",
              color: "#ffffff",
              border: 0,
              borderRadius: 4,
              fontWeight: "bold",
            }}
          >
            Save
          </button>
        </div>
      </MainContainer>
    </div>
  );
}

export default App;
