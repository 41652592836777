import React from "react";
import { Gradient, Infocimahi, InfocimahiIdentity } from "./styles";
import iweburl from "./../../images/iweburl.svg";
import iindentity from "./../../images/iindentity.svg";

const GradientComponent = ({ currWidth }) => {
  return (
    <>
      <Gradient />
      <Infocimahi src={iweburl} currWidth={currWidth} />
      <InfocimahiIdentity src={iindentity} currWidth={currWidth} />
    </>
  );
};

export default GradientComponent;
