import styled, { css } from "styled-components";

export const MainContainer = styled.div`
  background-color: #282c34;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ImageDiv = styled.div`
  max-height: 512px;
  max-width: 512px;
  width: 100%;
  height: 100%;
`;

export const ImageDivContent = styled.div`
  position: relative;
  padding-top: 100%;
  width: 100%;
`;

interface ImageProps {
  zIndex?: number;
}

export const Image = styled.img<ImageProps>`
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  ${({ zIndex }) =>
    zIndex &&
    css`
      z-index: zIndex;
    `}
`;

interface TemplateTextProps {
  fontScale: number;
  currWidth: number;
  paddingScale: number;
  topScale: number;
}

export const TemplateText = styled.p<TemplateTextProps>`
  position: absolute;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  font-family: Montserrat;
  color: #ffffff;
  white-space: pre-wrap;
  z-index: 2;
  text-align: left;
  ${(props: TemplateTextProps) =>
    props &&
    css`
      font-size: ${(72.86 / 1500) * props.currWidth}px;
      left: ${(77 / 1500) * props.currWidth}px;
      width: ${(1322 / 1500) * props.currWidth}px;
      height: ${(294 / 1500) * props.currWidth}px;
      top: ${(995 / 1500) * props.currWidth}px;
      line-height: ${(97.7 / 1500) * props.currWidth}px;
    `}
`;

interface SourceTextProps {
  sourceBottomScale: number;
  zIndex: number;
  currWidth: number;
  topSize: number;
}

export const SourceText = styled.p<SourceTextProps>`
  transform: rotate(-90deg);

  position: absolute;
  color: #ffffff;
  font-weight: bold;
  margin-block-end: 0em;
  margin-block-start: 0em;
  transform-origin: bottom right;
  right: 1.4%;
  font-size: ${(props) => (25 / 1500) * props.currWidth}px;
  z-index: ${(props) => props.zIndex};
  bottom: ${(props) =>
    props.sourceBottomScale * props.currWidth + props.topSize}px;
`;

export const Logo = styled.img<SourceTextProps>`
  ${(props: SourceTextProps) => css`
    position: absolute;
    width: ${(319 / 1500) * props.currWidth}px;
    height: ${(80 / 1500) * props.currWidth}px;
    left: ${(45 / 1500) * props.currWidth}px;
    top: ${(36 / 1500) * props.currWidth}px;
  `}
`;
