import React from "react";
import { Tag, Arrow } from "./styles";
import blade from "./../../images/blade.svg";
const TagComponent = ({ text, currWidth }) => {
  return (
    <div
      style={{
        marginLeft: (30 / 1500) * currWidth,
        textAlign: "left",
        position: "relative",
      }}
    >
      <Tag currWidth={currWidth}>{text}</Tag>
      <Arrow currWidth={currWidth} />
    </div>
  );
};

export default TagComponent;
