import styled from "styled-components";

interface Props {
    currWidth: number;
  }
export const Gradient = styled.div`
position: absolute;
left: 0%;
right: 0%;
top: 52.32%;
bottom: 0%;
background: linear-gradient(180deg, rgba(39, 35, 97, 0) 0%, rgba(29, 48, 101, 0.27) 26%, rgba(5, 83, 109, 0.95) 82%, #03556E 86%);
`

export const Infocimahi = styled.img<Props>`
position: absolute;
width:${({ currWidth }) => ( 349 / 1500) * currWidth}px;
height:${({ currWidth }) => (28 / 1500) * currWidth}px;
left:${({ currWidth }) => (36 / 1500) * currWidth}px;
top:${({ currWidth }) => (1430 / 1500) * currWidth}px;
`

export const InfocimahiIdentity = styled.img<Props>`
position: absolute;
width:${({ currWidth }) => (878/ 1500) * currWidth}px;
height: ${({ currWidth }) => ( 29/ 1500) * currWidth}px;
left: ${({ currWidth }) => (596/ 1500) * currWidth}px;
top: ${({ currWidth }) => (1430/ 1500) * currWidth}px;
`